<template>
  <section>
    <v-list two-line v-if="opere.length">
      <template v-for="(opera, index) in opere">
        <v-list-tile
          @click.stop="openOperaDialog(opera)"
          :key="'tile-'+opera._id">
          <v-list-tile-content>
            <v-list-tile-title v-text="opera.nome" />
            <v-list-tile-sub-title>
              <span v-if="!opera.prodotti || !opera.prodotti.length">Nessun prodotto</span>
              <span v-if="opera.prodotti && opera.prodotti.length===1">1 prodotto</span>
              <span v-if="opera.prodotti && opera.prodotti.length>1">{{ opera.prodotti.length }} prodotti</span>
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-chip small :color="!opera.attivo ? 'grey dark-3' : 'success'" text-color="white">
              <span v-if="opera.attivo">Attiva</span>
              <span v-if="!opera.attivo">Non attiva</span>
            </v-chip>
            {{ opera.codice }}
          </v-list-tile-action>
        </v-list-tile>
        <v-divider :key="opera._id" v-if="index < opere.length - 1" />
      </template>
    </v-list>
    <v-alert
      outline
      color="info"
      value="true"
      v-if="!opere.length">
      Nessuna opera trovata
    </v-alert>
    <!-- modale opera -->
    <v-dialog
      v-model="showOpera"
      transition="dialog-bottom-transition"
      :overlay="false"
      max-width="900">
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="showOpera=false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formOpera" v-model="validFormOpera" lazy-validation>
            <v-container fluid>
              <v-layout row wrap>
                <v-flex xs12 lg4>
                  <v-text-field
                    label="Nome opera*"
                    v-model="opera.nome"
                    name="nome"
                    :rules="[$rules.required, $rules.maxlength(200)]"
                  />
                </v-flex>
                <v-flex xs12 lg4>
                  <v-text-field
                    label="Codice opera"
                    v-model="opera.codice"
                    name="codice"
                    :rules="[$rules.maxlength(16)]"
                  />
                </v-flex>
                <v-flex xs12 lg4>
                  <v-select
                    label="Stato"
                    :items="stati"
                    item-text="nome"
                    item-value="id"
                    v-model="opera.attivo"
                  />
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12>
                  <v-divider />
                  <v-subheader class="mt-4 pb-2 pa-0"><h3>Prodotti</h3></v-subheader>
                </v-flex>
              </v-layout>
              <template v-for="(prodotto) in filteredTitles(opera.prodotti)">
                <v-layout row wrap :key="'tile-'+prodotto._id">
                  <v-flex xs12 sm5>
                    <v-text-field
                      label="Prodotto*"
                      v-model="prodotto.nome"
                      name="prodotto.nome"
                      :rules="[$rules.required, $rules.maxlength(200)]"
                    />
                  </v-flex>
                  <v-flex xs12 sm5>
                    <v-text-field
                      label="Codice suffisso prodotto"
                      v-model="prodotto.codice"
                      name="prodotto.codice"
                      :rules="[$rules.maxlength(20)]"
                    />
                  </v-flex>
                  <v-flex xs12 sm2>
                    <v-icon @click.stop="delTitle(prodotto)" class="mt-4" color="error">delete</v-icon>
                  </v-flex>
                </v-layout>
              </template>
              <v-btn color="pink darken-1 ml-0" dark small fab @click.stop="addTitle()">
                <v-icon color="white">add</v-icon>
              </v-btn>
              <v-layout row wrap>
                <v-flex xs12 lg6>
                  <v-btn
                    class="ml-0 mt-4"
                    color="primary"
                    :disabled="!validFormOpera"
                    id="salvaOpera"
                    @click.native="save">
                    Salva
                  </v-btn>
                  <v-btn
                    class="ma-0 mt-3"
                    color="error"
                    v-if="opera._id"
                    @click.stop="deleteOpera()">
                    Elimina opera
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- conferma elimina opera -->
    <v-dialog v-model="eliminaOpera" persistent max-width="250" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="headline">Sicuro?</v-card-title>
        <v-card-text>Stai per cancellare l’opera <strong>{{ opera.nome }}</strong>.</v-card-text>
        <v-card-actions>
          <v-btn @click.native="eliminaOpera = false">Annulla</v-btn>
          <v-btn color="error" @click.native="removeOpera">Elimina</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip left>
      <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click.stop="openOperaDialog()">
        <v-icon color="white">add</v-icon>
      </v-btn>
      <span>Nuova opera</span>
    </v-tooltip>
  </section>
</template>

<script>
import { $EventBus } from '@/eventBus'
import _clone from 'lodash/clone'
export default {
  data: () => ({
    cliente: null,
    opere: [],
    opera: {},
    showOpera: false,
    eliminaOpera: false,
    stati: [{ id: 1, nome: 'Attiva' }, { id: 0, nome: 'Non attiva' }],
    validFormOpera: false
  }),
  computed: {
    modalTitle () {
      return this.opera._id ? 'Modifica ' + this.opera.nome : 'Nuova opera'
    }
  },
  methods: {
    getOpere () {
      this.$plsqlReadAll('opera', { cliente: this.cliente, attivo: 2 })
        .then(response => {
          this.opere = response.data
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura opere' })
        })
    },
    save () {
      if (this.$refs.formOpera.validate()) {
        if (this.opera.prodotti && this.opera.prodotti.length) {
          this.opera.prodotti = this.opera.prodotti.filter(tit => { return tit._id > 0 || (tit.nome && !tit.deleted) })
        }
        if (this.opera.prodotti && this.opera.prodotti.length) {
          let hasErr = false
          this.opera.prodotti.forEach(tit => { hasErr = !tit.nome })
          if (hasErr || !this.opera.nome) {
            $EventBus.$emit('message', { type: 'error', text: 'Alcuni dati obbligatori non sono stati compilati. Correggi e riprova.' })
            return
          }
        }
        this.$plsqlSave('opera', this.opera)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Opera salvata'
            })
            this.showOpera = false
            this.getOpere()
          })
          .catch(err => {
            console.log(err)
            if (err.data.errCode === 'ORA-00001') {
              $EventBus.$emit('message', { type: 'error', text: 'Una opera con questo nome esiste già! Correggere e riprovare.' })
            } else {
              $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
            }
          })
      }
    },
    deleteOpera () {
      this.eliminaOpera = true
    },
    removeOpera () {
      this.$plsqlDelete('opera', this.opera._id)
        .then(response => {
          $EventBus.$emit('message', { type: 'success', text: 'Opera eliminata' })
          this.showOpera = this.eliminaOpera = false
          this.getOpere()
        })
        .catch(err => {
          if (err.data.errCode === 'ORA-02292') {
            $EventBus.$emit('message', { type: 'error', text: 'Non è possibile cancellare l\'opera perché usata' })
          } else {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          }
          this.eliminaOpera = false
        })
    },
    openOperaDialog (opera) {
      this.opera = opera ? _clone(opera) : { cliente_id: this.cliente, prodotti: [] }
      this.showOpera = true
      if (!this.opera._id) this.$refs.formOpera.reset()
    },
    filteredTitles (prodotti) {
      if (!prodotti) {
        return []
      }
      return prodotti.filter(tit => { return !tit.deleted })
    },
    delTitle (prodotto) {
      this.$set(prodotto, 'deleted', 1)
    },
    addTitle () {
      let lowestid
      if (!this.opera.prodotti) {
        this.$set(this.opera, 'prodotti', [])
      }
      lowestid = this.opera.prodotti.length
        ? this.opera.prodotti.reduce((min, t) => t._id < min ? t._id : min, this.opera.prodotti[0]._id)
        : 1
      if (lowestid > 0) {
        this.opera.prodotti.push({ _id: -1 })
      } else {
        this.opera.prodotti.push({ _id: lowestid - 1 })
      }
    }
  },
  mounted () {
    this.cliente = this.$route.params.id
    this.getOpere()
  }
}
</script>
